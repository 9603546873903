<template>
  <div>
    >
    <div v-if="playVideo">
      <video-player :options="videoOptions" />
    </div>
    <div class="modal-background" id="regModal">
      <div class="modal-content">
        <span class="close" @click="hideModal('regModal')"></span>
        <h4>Sorry!</h4>
        <h5>Something wrong, please try again later</h5>
      </div>
    </div>
    <div class="modal-background" id="regModal2">
      <div class="modal-content pop-02">
        <span class="close" @click="hideModal('regModal2')"></span>
        <h4>Please login to play game</h4>

        <router-link to="/registration" tag="button" class="submit-btn5"
          >Okay
        </router-link>
        <router-link to="/" tag="button" class="submit-btn6"
          >Cancel
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VideoPlayer from "@/components/VideoPlayer.vue";
// import video from "video.js";

export default {
  name: "VideoPlay",
  components: {
    VideoPlayer
  },
  data: () => {
    return {
      phone: localStorage.getItem("phone"),
      host: null,
      playVideo: false,
      videoOptions: {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        liveui: true,
        sources: [
          {
            src: null,
            type: "video/mp4"
          }
        ]
      }
    };
  },
  async created() {
    //alert(this.videoOptions.sources[0].src);
    this.host = this.$root.url();
    await this.startGame();
  },
  methods: {
    startGame() {
      let vm = this;
      if (!localStorage.getItem("phone")) {
        setTimeout(function() {
          vm.showModal("regModal2");
        }, 100);
        return false;
      } else {
        const path = "videoPlay";
        const url = `${this.host}/${path}?phone=${this.phone}&videoId=${this.$route.params.videoId}`;
        //console.log(url);
        axios
          .get(url)
          .then(response => {
            console.log(response.data);
            if (response.data.success === true) {
              // this.videoSrc = this.host + response.data.link;
              this.videoOptions.sources[0].src = response.data.link;
              this.playVideo = true;
            } else {
              if (response.data.code === 3004) {
                localStorage.clear();
                vm.$router.push({ name: "Registration" });
              }
              this.showModal("regModal");
            }
          })
          .catch(() => {
            this.showModal("regModal");
          });
      }
    },
    showModal: function(id) {
      $("#" + id).fadeIn(200);
      $("body").toggleClass("no-scroll");
    },
    hideModal: function(id) {
      $("#" + id).fadeOut(400);
      $("body").toggleClass("no-scroll");
    }
  }
};
</script>
<style scoped>
/*@import '../../node_modules/video.js/dist/video-js.min.css';*/
</style>
